import { MouseEventHandler, ReactElement } from 'react';
import * as S from './BottomButton.styles';

type BottomButtonProps = {
    title: string;
    isTripsList?: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
};

export const BottomButton = ({ title, isTripsList, onClick }: BottomButtonProps): ReactElement => {
    return (
        <S.Container onClick={onClick} withSpace={!isTripsList}>
            {title}
            <S.Arrow />
        </S.Container>
    );
};
