import { memo, ReactElement } from 'react';

import { useAttractionsContext } from '@/common/app/contexts/AttractionsContext';
import { Container } from '@/common/ui/containers/Container';
import { ActivitiesListProps } from './ActivitiesList.domain';

import * as S from './ActivitiesList.styles';
import { BottomButton } from '@/common/ui/buttons/BottomButton/BottomButton';
import { Preloader } from '@/common/ui/loaders/Preloader';
import SortDropdown from './SortDropdown/SortDropdown';
import { NewActivitiesPreview } from '@/entities/Activity/ui/ActivitiesPreview/NewActivitiesPreview';
import { Attributes } from './Attributes/Attributes';
import { NewFeaturedActivities } from '@/shared/FeaturedActivities/ui/NewFeaturedActivities';
import { useMedia } from '@/common/service/hooks/useMedia';

export const ActivitiesListContainer = ({
    hideSort,
    listNameGA3,
    listName,
    listId,
    availableAttributes,
    recommends,
    item_list_id,
    item_list_name,
    placeholders,
}: ActivitiesListProps): ReactElement => {
    const { onScrollPage, finalList, totalLength, loading } = useAttractionsContext();
    const isDesktop = useMedia('(min-width: 992px)');

    return (
        <S.Wrapper>
            <Container>
                {!hideSort && <SortDropdown />}
                {!!availableAttributes.length && (
                    <Attributes availableAttributes={availableAttributes} />
                )}
                {loading.changeLoading ? (
                    <Preloader minHeight="100vh" />
                ) : (
                    <>
                        <S.Content data-test-id="Activities_list_content">
                            {finalList.map((activity, index) => (
                                <>
                                    {!!recommends?.length && !!index && index % 10 === 0 && (
                                        <S.RecommendsBlock key={index + '_recommended'}>
                                            <NewFeaturedActivities
                                                defaultActivities={recommends ?? []}
                                                title=""
                                                isFromSearch
                                                item_list_id={item_list_id}
                                                item_list_name={item_list_name}
                                                placeholders={placeholders}
                                                withoutDots
                                            />
                                        </S.RecommendsBlock>
                                    )}
                                    <NewActivitiesPreview
                                        activity={activity}
                                        key={activity.id}
                                        position={index}
                                        listName={listName}
                                        listId={listId}
                                        listNameGA3={listNameGA3}
                                        isComplete={
                                            finalList.length >= totalLength ||
                                            loading.initLoading ||
                                            loading.paginationLoading
                                        }
                                        isDesktop={isDesktop}
                                    />
                                </>
                            ))}
                        </S.Content>
                        {loading.paginationLoading && <Preloader />}
                        {totalLength > finalList.length && !loading.paginationLoading && (
                            <BottomButton
                                title={`Show ${
                                    totalLength - finalList.length
                                } more tours & activities`}
                                onClick={() => onScrollPage({})}
                                isTripsList
                            />
                        )}
                    </>
                )}
            </Container>
        </S.Wrapper>
    );
};

export const ActivitiesList = memo(ActivitiesListContainer);
